/* Clases personalizadas */

#root {
  max-width: 1080px;
  margin: auto;
}

body,
html {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
  min-width: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.social {
  background-color: #000 !important;
}

.heroOverlay {
  min-height: 500px;
  background: linear-gradient(160deg,
      black 15%,
      rgba(100, 100, 100, 00) 100%) !important;
}

.heroContainer {

  min-height: 500px;

  margin: 0em;

  /* Ruta de la imagen de fondo */
  background-size: cover;
  /* Imagen de fondo cubriendo el contenedor */
  background-position: center;
  /* Centrar la imagen de fondo */
}

.materialsContainer {

  min-height: 400px;
  margin: 0em;



}



.qualityContainer {

  min-height: 400px;
  margin: 0em;



}


.imagenCalidad {
  padding: 10px;
  border-radius: 20px;
  max-height: 25vh;
  object-fit: cover;
}

.imagenCalidad2 {


  object-fit: cover;
  margin: 0;
  padding: 0;
  margin-bottom: 2em;
  max-height: 25vh;


}


@media only screen and (min-width: 768px) {
  .heroContainer {
    border-radius: 40px !important;
    margin: 1.5em;
    border-width: 10px !important;

  }

  .heroOverlay {
    border-radius: 40px !important;
    background: linear-gradient(160deg,
        black 15%,
        rgba(100, 100, 100, 00) 80%) !important;
  }

  .materialsContainer {
    margin: 1.5em;
    border-radius: 40px;
    border-style: solid;
    border-width: 3px;
  }


  .qualityContainer {
    margin: 1.5em;

  }

  .imagenCalidad2 {

    max-height: 40em;
    object-fit: cover;


  }

}

.navegacion {
  min-height: 60px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.hero-image {
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center center;
}

.hero {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 10em !important;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+41,ffffff+100&1+0,0+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.7) 20%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  /* IE6-9 */
}

.hero>a {
  color: navy;
}

.hero>a:hover {
  text-decoration: none;
}

.heroLink h1 {
  color: rgb(80, 110, 110);
  transition: all 0.2s ease-in-out;
  text-shadow: 3px 3px 5px #000;
}

.heroLink h1:hover {
  color: rgb(100, 120, 120);
  transform: scale(1.01);
  text-shadow: 3px 3px 10px #000;
}

.semitrans {
  background-color: rgba(255, 255, 255, 0.5);
}

.idiomas {
  right: 20px;
}

.navbarLogo {
  left: 10px;
  top: 10px;
}

.iconOverlay {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
}

.iconOverlay i {
  transition: all 0.2s ease-in-out;
}

.iconOverlay a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.iconOverlay i:hover {
  transform: scale(1.3);
}

.iconActive {
  transform: invert(50%);
}

.carousel-item {
  height: 70vh;
  min-height: 512px;
  max-height: 768px;
  width: 100%;


}

.carousel-inner>.carousel-item>img {
  height: 75vh;
  min-height: 512px;
  max-height: 768px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 1;

}

ol.carousel-indicators {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  float: left;
  width: 33%;
  height: 15px;
  margin: 0;
  border-radius: 0;
  background: white;
}

ol.carousel-indicators li.active {
  background: #343a40;
}

.full-screen-container {
  background: white;
  position: relative;
  overflow: scroll;
}

.full-screen-image {
  position: center center;
  position: relative;
  overflow: scroll;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 900;
}

.docThumbnail {
  width: 150px !important;
  width: 200px !important;
}

.direccion {
  border: none !important;
}

.medidas {
  height: 150px;
  width: auto;
}

.listaColecciones {
  min-width: 70vw !important;
  max-width: 80vw !important;
}

.ubicacion {
  min-height: 400px;
  width: 100% !important;
  margin: 0;
  padding: 0;
  position: relative;

}

.custom-btn-circle {
  border-radius: 50% !important;
  width: 2em !important;
  height: 2em !important;
  min-width: 2em !important;
  min-height: 2em !important;
  max-width: 2em !important;
  max-height: 2em !important;
  padding: 0.2em !important;
  margin-left: 1em;
}

.bg-light-gray {
  background-color: rgba(220, 220, 220, 1) !important;
}

.bg-lighter-gray {
  background-color: rgba(240, 240, 240, 1) !important;
}

.icon-muted i {
  color: rgba(200, 200, 200, 1);
}

.stickToBottom {
  position: fixed;
  bottom: 0;
  z-index: 10;
}


.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%244b' stroke='%23b44' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;

}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg'  fill='%2344b' stroke='%23b44' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}